<template>
    <div id="map">
      <div v-for="(post, idx) in posts" :key="idx" >
      </div>
    </div>

</template>

<script>
import { firestore } from '@/firebase/firestore'
import { mapMutations } from 'vuex'
import * as types from '@/vuex/mutation_types'

export default {
  name: 'Map',
  data () {
    return {
      map: null,
      posts: [],
      markers: [],
      submarkers: [
        {
          title: '안 케 머 리 여',
          lat: 33.4013234,
          lng: 126.9061154,
          deg: 80
        },
        {
          title: '센 동 산',
          lat: 33.3979612,
          lng: 126.9072537,
          deg: 70
        },
        {
          title: '여 아 진 계',
          lat: 33.3965432,
          lng: 126.9068718,
          deg: 110
        },
        {
          title: '등 대',
          lat: 33.39505,
          lng: 126.9113604,
          deg: 0
        },
        {
          title: '도 예 코 지',
          lat: 33.3941367,
          lng: 126.9068619,
          deg: 95
        },
        {
          title: '밖 코 지',
          lat: 33.3912093,
          lng: 126.9058456,
          deg: -40
        },
        {
          title: '톳 여',
          lat: 33.3885296,
          lng: 126.8997231
        },
        {
          title: '알 빠 르',
          lat: 33.3904242,
          lng: 126.9024751
        },
        {
          title: '수 승 코 지 여',
          lat: 33.3897031,
          lng: 126.9036552
        },
        {
          title: '수 승 코 지 불 턱',
          lat: 33.3903212,
          lng: 126.9045189
        },
        {
          title: '오 갈 여',
          lat: 33.3899808,
          lng: 126.9045779
        },
        {
          title: '초 소',
          lat: 33.3917993,
          lng: 126.9053772
        },
        {
          title: '개 머 리 밭 여',
          lat: 33.3920546,
          lng: 126.9110045
        },
        {
          title: '용 머 리',
          lat: 33.3995295,
          lng: 126.9055287,
          deg: 80
        },
        {
          title: '안 케 머 리',
          lat: 33.4013234,
          lng: 126.9042829,
          deg: 120
        },
        {
          title: '검 은 빌 레',
          lat: 33.4028973,
          lng: 126.904197,
          deg: 0
        },
        {
          title: '몰 래 알',
          lat: 33.4037178,
          lng: 126.9045565,
          deg: 0
        },
        {
          title: '노 꼰 도 머 흘',
          lat: 33.3947015,
          lng: 126.909261,
          deg: 0
        },
        {
          title: '산 여',
          lat: 33.3957137,
          lng: 126.9115731,
          deg: 0
        },
        {
          title: '웡 이 여',
          lat: 33.3949971,
          lng: 126.9120774,
          deg: 0
        },
        {
          title: '중 여',
          lat: 33.4025256,
          lng: 126.9087461,
          deg: 0
        }

      ]
    }
  },
  firestore () {
    return {
      posts: firestore
        .collection('Post')
        .where('show', '==', true || false)
    }
  },
  methods: {
    initMap () {
      var Bounds = new naver.maps.LatLngBounds(
        new naver.maps.LatLng(33.370383, 126.890368),
        new naver.maps.LatLng(33.401924, 126.915543))

      var container = document.getElementById('map')
      var options = {
        center: new naver.maps.LatLng(33.3945, 126.9077),
        zoom: 16,
        maxZoom: 18,
        minZoom: 15,
        maxBounds: Bounds,
        zoomControl: false,
        zoomControlOptions: {
          style: naver.maps.ZoomControlStyle.SMALL,
          position: naver.maps.Position.TOP_RIGHT
        }
      }

      var map = new naver.maps.Map(container, options)
      map.setMapTypeId(naver.maps.MapTypeId.SATELLITE)
      this.map = map
    },
    makerMarker (post) {
      var marker = new naver.maps.Marker({
        map: this.map,
        position: new naver.maps.LatLng(post.lat, post.lng),
        title: post.title,
        icon: {
          content: [
            '<span class="small white--text marker text-shadow">',
            post.title,
            '</span>'
          ].join(''),
          size: new naver.maps.Size(100, 100),
          anchor: new naver.maps.Point(50, 50)
        }
      })
      var vm = this
      if (post.show) {
        naver.maps.Event.addListener(marker, 'click', function () {
          vm.markers.forEach(marker => {
            marker.eventTarget.classList.remove('active-marker')
          })
          marker.eventTarget.classList.add('active-marker')
          vm.map.panToBounds(
            naver.maps.LatLngBounds(
              naver.maps.LatLng(post.lat + 0.001, post.lng))
          )
          vm.setKey(post['.key'])
          vm.setTitle(post.title)
          vm.setContent(post.content)
          vm.setYoutube(post.youtube)
          vm.setLat(post.lat)
          vm.setLng(post.lng)
          vm.$router.push({ name: 'post', params: { key: post['.key'] } })
        })
        this.markers.push(marker)
      }
    },
    makerSubmarker (sub) {
      var marker = new naver.maps.Marker({ // eslint-disable-line no-unused-vars
        map: this.map,
        position: new naver.maps.LatLng(sub.lat, sub.lng),
        title: sub.title,
        clickable: false,
        icon: {
          content: [
            '<span class="small submarker" style="transform:rotate(',
            sub.deg,
            'deg);">',
            sub.title,
            '</span>'
          ].join('')
        }
      })
    },
    ...mapMutations({
      setKey: types.SET_KEY,
      setTitle: types.SET_TITLE,
      setContent: types.SET_CONTENT,
      setYoutube: types.SET_YOUTUBE,
      setLat: types.SET_LAT,
      setLng: types.SET_LNG
    }),
    resetZoom () {
      this.map.setZoom(16, true)
      this.markers.forEach(marker => {
        marker.eventTarget.classList.remove('active-marker')
      })
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
    }
  },
  mounted () {
    /* global naver */
    this.initMap()
    var vm = this
    naver.maps.Event.addListener(this.map, 'click', function (e) {
      vm.resetZoom()
      console.log(e)
    })
  },
  updated () {
    this.posts.forEach(element => {
      this.makerMarker(element)
    })
    this.submarkers.forEach(element => {
      this.makerSubmarker(element)
    })
  }
}
</script>

<style scoped>
#map{
    width:100% !important;
    height:100% !important;
    position: absolute !important;
  }
</style>
