<template>
  <v-app>
    <Map></Map>
    <v-main>
      <v-container>
          <transition name="fade">
            <router-view/>
          </transition>
      </v-container>
    </v-main>

  </v-app>

</template>

<script>
import Map from '@/components/Map'

export default {
  components: {
    Map
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}

</style>
