<template>
  <span>
    <span class="pointer" @click.stop="dialog = true">박정근</span>
    <v-dialog width="500" v-model="dialog">
      <v-card class="rounded-0">
        <v-card-title class="pb-16">
          해녀보다 빨리늙는 바다
          <br>
          <small>The Sea Ageing, Faster Than Women Divers</small>
        </v-card-title>
        <v-card-text>
            <hr>
            <h5 class="pb-2">송명자(해녀)</h5>
            <p class="pb-5"> 온평리 바당에 감도는 짠바람을 맞으며 30년 넘는 물질을 이어온 상군해녀다. 온평리 해녀들 중 나이가 가장 어리지만 가장 물질을 잘하는 상군해녀이기도 하다. 젊고 활달한 기질 덕분에 수많은 연구자들의 주목을 받아왔고, 음악을 하는 아들처럼 예술적인 감성이 매우 풍부하다.</p>
            <hr>
            <h5 class="pb-2">문봉순</h5>
            <p class="pb-5">굿 공부를 하겠다고 제주도에 왔지만, 정작 공부는 않고 사람들과 잘 노는 궁리를 하며 신당과 제주굿, 무형문화재를 주제로 전시, 강좌를 진행했다. 요즘은 제주사람들의 살아온 이야기 듣는 일을 제일 큰 재미로 삼고 있다. 어머니.할머니.작은어머니.동네 삼촌까지 모두가 연결된 여성들의  연대와 그 연대 안에서 전달되는 삶의 지혜와 생존의 기술을 발견하는 것. 오늘의 내 자파리이자 숙제이다.</p>
            <hr>
            <h5 class="pb-2">박정근</h5>
            <p class="pb-5">바다가 없는 내륙의 깊은 산골에서 나고 자란 사진가다. 밀물지는 파도에 이끌리듯 제주섬으로 들어와 카메라를 든 지 10년 다 되어간다. 앵글에 잡히는 아름다운 풍광의 실루엣 너머에 있는 해녀, 4.3, 굿 등을 만나며 제주의 내밀한 사연을 만나고 있다. 아직도 제주가 어렵다.</p>
        </v-card-text>
         <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            닫기
          </v-btn>
         </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  data () {
    return {
      dialog: false
    }
  }
}
</script>
