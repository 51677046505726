export default {
  getKey: state => state.key,
  getTitle: state => state.title,
  getContent: state => state.content,
  getDate: state => state.date,
  getImgUrl: state => state.imgUrl,
  getYoutube: state => state.youtube,
  getLat: state => state.lat,
  getLng: state => state.lng,
  getUser: state => state.user
}
