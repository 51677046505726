<template>
  <v-col offset-sm="3" sm="6" lg="4" offset-lg="4">
    <v-card class="overflow-y-auto" max-height="60vh" elevation="10">
      <youtube :video-id="getYoutube" :player-vars="playerVars" ref="youtube" :resize="true" :fitParent="true"></youtube>
      <v-card-text>
        <span class="headline">{{ getTitle }}</span>
        <span v-html="imgResizedContent"></span>
      </v-card-text>
      <v-card-actions>
          <div v-if="getUser" class="pa-3">
            <v-btn @click="update" class="ma-2">edit</v-btn>
            <v-btn @click="makeHidden()" class="ma-2">delete</v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            router-link to="/" >닫기
          </v-btn>

         </v-card-actions>

    </v-card>
  </v-col>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import * as types from '@/vuex/mutation_types'
import { firestore } from '@/firebase/firestore'
import _ from 'lodash'

export default {
  mounted () {},
  computed: {
    ...mapGetters([
      'getKey',
      'getTitle',
      'getContent',
      'getDate',
      'getYoutube',
      'getLat',
      'getLng',
      'getImgUrl',
      'getUser'
    ]),
    imgResizedContent () {
      return _.replace(this.getContent, new RegExp('img src', 'g'), 'img width="100%" src')
    }
  },
  data () {
    return {
      playerVars: {
        autoplay: 1
      }
    }
  },
  created () {
    if (this.getKey === '') this.getPost()
  },
  methods: {
    ...mapMutations({
      setKey: types.SET_KEY,
      setTitle: types.SET_TITLE,
      setContent: types.SET_CONTENT,
      setDate: types.SET_DATE,
      setImgUrl: types.SET_IMG_URL,
      setYoutube: types.SET_YOUTUBE,
      setLat: types.SET_LAT,
      setLng: types.SET_LNG
    }),
    update () {
      this.$router.push('/updater')
    },
    makeHidden () {
      firestore
        .collection('Post')
        .doc(this.getKey)
        .update({
          show: false
        })
        .then(() => {
          this.$router.push('/')
        })
        .catch((error) => {
          console.error('Error on remove: ', error)
        })
    },
    getPost () {
      firestore
        .collection('Post')
        .doc(this.$route.params.key)
        .get()
        .then(doc => {
          const post = doc.data()
          this.setKey(this.$route.params.key)
          this.setTitle(post.title)
          this.setContent(post.content)
          this.setDate(post.date.seconds)
          this.setImgUrl(post.imgUrl)
          this.setYoutube(post.youtube)
          this.setLat(post.lat)
          this.setLng(post.lng)
        })
        .catch(error => {
          console.error(`getPost error: ${error}`)
        })
    }
  }
}
</script>

<style scoped>

</style>
