export const SET_KEY = 'setKey'
export const SET_TITLE = 'setTitle'
export const SET_CONTENT = 'setContent'
export const SET_DATE = 'setDate'
export const SET_IMG_URL = 'setImgUrl'
export const SET_YOUTUBE = 'setYoutube'
export const SET_LAT = 'setLat'
export const SET_LNG = 'setLng'
export const SET_USER = 'setUser'
export const INIT_ARTICLE_DATA = 'init'
