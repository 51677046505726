<template>

    <v-card max-height="70vh" class="pa-3 overflow-y-auto">
      <v-form>
        <v-text-field
          v-model="title"
          label="Title"
          required>
        </v-text-field>
        <v-text-field
          v-model="youtube"
          label="youtube"
          required>
        </v-text-field>
        <v-text-field
          type="number"
          v-model="lat"
          label="lat"
          required>
        </v-text-field>
        <v-text-field
          type="number"
          v-model="lng"
          label="lng"
          required>
        </v-text-field>
      </v-form>
      <vue-editor
          id="writer"
          v-model="content"
          useCustomImageHandler
          @imageAdded="handleImageAdded"
      />
      <v-btn block @click="savePost">save</v-btn>
    </v-card>

</template>

<script>
import { VueEditor } from 'vue2-editor'
import { firestore } from '@/firebase/firestore'
import { firestorage } from '@/firebase/firestorage'
import { mapGetters, mapMutations } from 'vuex'
import * as types from '@/vuex/mutation_types'

export default {
  components: {
    VueEditor
  },
  created () {
    if (this.$route.name === 'writer') this.initArticleData()
    if (this.getKey !== '') this.oldImgUrl = this.getImgUrl
  },
  computed: {
    ...mapGetters(['getKey', 'getTitle', 'getContent', 'getImgUrl', 'getWriter', 'getUser', 'getYoutube', 'getLat', 'getLng']),
    title: {
      get () {
        return this.getTitle
      },
      set (value) {
        this.updateTitle(value)
      }
    },
    youtube: {
      get () {
        return this.getYoutube
      },
      set (value) {
        this.updateYoutube(value)
      }
    },
    lat: {
      get () {
        return this.getLat
      },
      set (value) {
        this.updateLat(value)
      }
    },
    lng: {
      get () {
        return this.getLng
      },
      set (value) {
        this.updateLng(value)
      }
    },
    content: {
      get () {
        return this.getContent
      },
      set (value) {
        this.updateContent(value)
      }
    }
  },
  methods: {
    ...mapMutations({
      updateTitle: types.SET_TITLE,
      updateYoutube: types.SET_YOUTUBE,
      updateLat: types.SET_LAT,
      updateLng: types.SET_LNG,
      updateContent: types.SET_CONTENT,
      initArticleData: types.INIT_ARTICLE_DATA
    }),
    savePost () {
      firestore
        .collection('Post')
        .doc(this.getKey || new Date().getTime().toString())
        .set({
          title: this.title,
          youtube: this.youtube,
          lat: parseFloat(this.lat),
          lng: parseFloat(this.lng),
          content: this.content,
          date: {
            seconds: new Date().getTime(),
            nanoseconds: 0
          },
          imgUrl: this.imgUrl || this.getImgUrl,
          show: true
        })
        .then(() => this.$router.push('/'))
        .catch((error) => {
          console.error(`Error adding document: ${error}`)
        })
    },
    getDownloadUrl (v) {
      this.imgUrl = v
    },
    handleImageAdded (file, Editor, cursorLocation) {
      const uploadTask = firestorage.ref('images/' + file.name).put(file)
      uploadTask.on('state_changed', snapshot => {
      }, error => {
        console.error(`Upload error occured: ${error}`)
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log('File available at', downloadURL)
          Editor.insertEmbed(cursorLocation, 'image', downloadURL)
        })
      })
    }
  }
}
</script>

<style>
#writer .ql-editor {
  min-height: 300px;
}
</style>
