export default {
  firebase: {
    apiKey: 'AIzaSyDt1F-NLTh-hpZBaBj4g4BZbrFOY4N4_9U',
    authDomain: 'jejubadang-70396.firebaseapp.com',
    databaseURL: 'https://jejubadang-70396.firebaseio.com',
    projectId: 'jejubadang-70396',
    storageBucket: 'jejubadang-70396.appspot.com',
    messagingSenderId: '467381793006',
    appId: '1:467381793006:web:aace9346367ca19b7bc3ff',
    measurementId: 'G-9EBWYQ47P4'
  }
}
