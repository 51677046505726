import Vue from 'vue'
import Router from 'vue-router'
import Landing from '@/views/Landing'
import Post from '@/views/Post'
import Writer from '@/components/Writer'
import Login from '@/components/Login'
import store from '@/vuex/store'

Vue.use(Router)

const requireAuth = () => (from, to, next) => {
  if (store.getters.getUser) return next() // isAuth === true면 페이지 이동
  next('/') // isAuth === false면 다시 로그인 화면으로 이동
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'landing',
      component: Landing
    },
    {
      path: '/post/:key',
      name: 'post',
      component: Post
    },
    {
      path: '/writer',
      name: 'writer',
      component: Writer,
      beforeEnter: requireAuth()
    },
    {
      path: '/admin',
      name: 'login',
      component: Login
    },
    {
      path: '/updater',
      name: 'updater',
      component: Writer,
      beforeEnter: requireAuth()
    }
  ]
})
