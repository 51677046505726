<template>
  <v-container>
  <v-btn rounded="xl" size="x-large" elevation="12" class="beforeageing" target="_blank" :href="'http://before.theageingsea.net/'">우죽은 감태 </v-btn>
  <v-col cols="10" sm="4" lg="3" class="white--text text-shadow text-back" id="desc">
      <div class="mb-2" >
        <h2>
          <span class="font-weight-black pointer" @click="show = !show">
          해녀보다
          <br>
          빨리늙는 바다
          </span>
        </h2>
        <small class="font-weight-medium pointer" @click="show = !show">
          The Sea Ageing,<br class="d-block d-md-none"> Faster Than Women Divers
        </small>
      </div>

    <v-expand-transition>
      <div v-show="!show">
          <hr>
          <br>
          해녀 삼춘에게 물었다. “삼춘, 언제까지 물질할 꺼?” 삼춘이 답했다. “나는 언제까지고 하고픈데, 나보다 바다가 늙어 못할 거라. 해녀가 줄어 바다에 잡을 게 많아질 줄 알았는데 세상이 발전하는 만큼 바다는 늙어가더라.”
          <br>
          <br>
          작가는 7년간 온평리에서 해녀들과 일상을 함께 하며 그들의 삶을 경청하고, 그들이 물질해온 바다를 기록해왔고,
          현재의 장면을 추가하여 ‘바다가 늙어간다.’는 해녀의 표현에 담겨있는 바다 오염과 7년 전부터 현재까지의 바다의 변화를 영상과 사진, 글로 표현하고자 하였다.
          <br><br>
      </div>
    </v-expand-transition>
      <hr>
      <p>
      <small class="font-weight-medium">
      Copyright&copy;2020 <Prologue></Prologue>
      <br class="d-block d-md-none">
      All rights reserved</small>
      </p>
  <div v-if="getUser">
    <router-link to="/writer" class="pa-2 white--text text-caption"><i class="far fa-copy"></i></router-link>
    <a @click="signOut" class="pa-2 white--text text-caption"><i class="fas fa-sign-out-alt"></i></a>
  </div>

  </v-col>
</v-container>
</template>

<script>
import Prologue from '@/components/Prologue'
import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    show: false
  }),
  components: { Prologue },
  computed: {
    ...mapGetters(['getUser'])
  },
  methods: {
    ...mapActions(['logout']),
    signOut () {
      this.logout()
      if (this.$route.path !== '/') this.$router.replace('/')
    }
  },
  mounted () {
    if (window.innerWidth > 768) {
      this.show = true
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
